var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Ubah' : 'Tambah') + " " + (_vm.$route.meta.name.singular)),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Posisi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Posisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"position_name","reduce":function (option) { return option.id; },"options":_vm.positionItems,"placeholder":"Ketik untuk mencari...","filterable":true},model:{value:(_vm.position_id),callback:function ($$v) {_vm.position_id=$$v},expression:"position_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Deskripsi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Deskripsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.submitErrors.description
                        ? false
                        : null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.description))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Potongan","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Jenis Potongan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option.value; },"label":"label","options":_vm.sharing_fee_type_option},model:{value:(_vm.admin_fee_discount_type),callback:function ($$v) {_vm.admin_fee_discount_type=$$v},expression:"admin_fee_discount_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Biaya Standar","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Biaya Standar","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.admin_fee
                        ? false
                        : null},model:{value:(_vm.admin_fee),callback:function ($$v) {_vm.admin_fee=$$v},expression:"admin_fee"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.admin_fee))])]}}])})],1),_c('hr')],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b',[_vm._v("Jumlah Talent Minimal")])]),_c('b-col',{attrs:{"md":"5"}},[_c('b',[_vm._v("Biaya Potongan")])])],1),_vm._l((_vm.talent_admin_fee_discounts),function(talent_admin_fee_discount,index){return _c('b-row',{key:("talent_admin_fee_discounts_" + index),staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"5"}},[_c('validation-provider',{attrs:{"name":("Jumlah Talent ke-" + (index + 1)),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(talent_admin_fee_discount.minimum_total_talent),callback:function ($$v) {_vm.$set(talent_admin_fee_discount, "minimum_total_talent", $$v)},expression:"talent_admin_fee_discount.minimum_total_talent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"5"}},[_c('validation-provider',{attrs:{"name":("Jumlah Biaya Talent ke-" + (index + 1)),"rules":("required|integer|max_value:" + _vm.admin_fee)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(_vm.admin_fee_discount_type === 'rp')?_c('money',_vm._b({staticClass:"form-control",model:{value:(talent_admin_fee_discount.admin_fee_discount_value),callback:function ($$v) {_vm.$set(talent_admin_fee_discount, "admin_fee_discount_value", $$v)},expression:"talent_admin_fee_discount.admin_fee_discount_value"}},'money',_vm.money,false)):_c('b-form-input',{attrs:{"type":"number"},model:{value:(talent_admin_fee_discount.admin_fee_discount_value),callback:function ($$v) {_vm.$set(talent_admin_fee_discount, "admin_fee_discount_value", $$v)},expression:"talent_admin_fee_discount.admin_fee_discount_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{attrs:{"variant":"outline-danger","type":"button"},on:{"click":function($event){return _vm.removeTalentAdminFeeDiscounts(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"18"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('hr')])],1)}),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"outline-danger","type":"button"},on:{"click":_vm.addTalentAdminFeeDiscounts}},[_vm._v(" Tambah Kolom ")])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }